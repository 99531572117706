<template>
  <div style="max-width: 900px; min-height: 200px; height: 200px">
    <form-wizard
      color="#fe671d"
      :title="null"
      :subtitle="null"
      shape="rounded"
      finish-button-text="Submit"
      :back-button-text="$t('back')"
      :next-button-text="$t('next')"
      :back-button-class="['btn', 'btn-primary']"
      :next-button-class="['btn', 'btn-secondar']"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- accoint details tab -->
      <tab-content :title="$t('plantType')" :before-change="validationForm">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">
              {{ $t("plantType") }}
            </h5>
            <small class="text-muted">
              {{ $t("selectYourPlantType") }}
            </small>
          </b-col>
          <div class="d-flex justify-content-around w-100">
            <b-button
              class="d-flex flex-column"
              :variant="plantType == 1 ? 'success' : 'info'"
              @click="plantType = 1"
            >
              <span
                class="mt-2 mx-auto w-100 mb-2"
                style="font-weight: bold; font-size: 1.6rem"
                >{{ $t("dairy") }}</span
              >
              <milk-svg height="90" width="100" />
            </b-button>
            <b-button
              class="d-flex flex-column"
              size="sm"
              :variant="plantType == 2 ? 'success' : 'info'"
              @click="plantType = 2"
            >
              <span
                class="mt-2 mx-auto w-100 mb-2"
                style="font-weight: bold; font-size: 1.6rem"
                >{{ $t("beef") }}</span
              >
              <beef-svg height="90" width="100" />
            </b-button>
          </div>
        </b-row>
      </tab-content>

      <!-- social link -->
      <tab-content
        :title="$t('companySettings')"
        :before-change="validationFormSocial"
      >
        <b-row>
          <b-col sm="6">
            <b-form-group :label="$t('currencySymbol')" label-for="currency">
              <b-form-input
                v-model="companySettings.symbol"
                placeholder="currency"
                name="currency"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group :label="$t('ingredientPrecision')" label-for="symbol">
              <v-select
                v-model="companySettings.ingredientdecimal"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="valueOptions"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('pricePrecision')"
              label-for="pricedecimal"
            >
              <v-select
                v-model="companySettings.pricedecimal"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="valueOptions"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('nutrientPrecision')"
              label-for="nutrientdecimal"
            >
              <v-select
                v-model="companySettings.nutrientdecimal"
                transition=""
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="valueOptions"
                :reduce="(option) => option.value"
              />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group :label="$t('symbolfront')" label-for="symbol">
              <b-form-checkbox
                id="symbol"
                v-model="companySettings.symbolinfront"
              />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              :label="$t('forceProduct')"
              label-for="productRequired"
            >
              <b-form-checkbox
                id="productRequired"
                v-model="companySettings.forceproduct"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { BeefSvg } from "@/assets/images/icons/beef.svg";
import MilkSvg from "@/assets/images/icons/milk.svg";
import { FormWizard, TabContent } from "vue-form-wizard";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
export default {
  components: {
    FormWizard,
    TabContent,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // vSelect,
    BeefSvg,
    MilkSvg,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    vSelect,
  },
  data() {
    return {
      plantType: 0,
      companySettings: {
        symbol: "₺",
        symbolinfront: true,
        forceproduct: false,
        ingredientdecimal: "3",
        nutrientdecimal: "3",
        pricedecimal: "3",
      },
      valueOptions: [
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
      ],
    };
  },
  mounted() {
    const { isNavMenuHidden, navbarType } = useAppConfig();
    navbarType.value = "hidden";
    isNavMenuHidden.value = true;

    var breadcump = document.querySelector(".content-header");
    if (breadcump) breadcump.style.display = "none";

    var appContent = document.querySelector(".app-content");
    if (appContent) {
      appContent.classList.add("d-flex");
      appContent.classList.add("flex-column");
      appContent.classList.add("justify-content-center");
      appContent.classList.add("align-items-center");
    }
  },
  destroyed() {
    const { isNavMenuHidden, navbarType } = useAppConfig();
    navbarType.value = "floating";
    isNavMenuHidden.value = false;

    var breadcump = document.querySelector(".content-header");
    if (breadcump) breadcump.style.display = "block";

    var appContent = document.querySelector(".app-content");
    if (appContent) {
      appContent.classList.remove("d-flex");
      appContent.classList.remove("flex-column");
      appContent.classList.remove("justify-content-center");
      appContent.classList.remove("align-items-center");
    }
  },
  methods: {
    async formSubmitted() {
      var user = getUserData();
      var plants = await this.$store.dispatch("plantsModule/fetchPlants");
      var mainPlantId = plants[0].id;
      var plantTypeResult = await this.$store.dispatch(
        "plantsModule/updatePlantType",
        { plantid: mainPlantId, planttype: this.plantType }
      );

      var result = await this.$http.put(
        `/Companies/UpdateForceandSymbol/${user.companyid}`,
        this.companySettings
      );
      //string apiResponse = await response.Content.ReadAsStringAsync();
      var isOK = result.status == 200;
      if (isOK && plantTypeResult) {
        var result = await this.$http.put(
          `/Companies/UpdateCompanyBoarding/${user.companyid}`
        );

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$t("updated", { type: this.$t("company") }),
          },
        });
      } else
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$t("unsuccessful"),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t("notUpdated", { type: this.$t("company") }),
          },
        });
      user.currency = this.companySettings.symbol;
      user.pricedecimal = this.companySettings.pricedecimal;
      user.nutrientdecimal = this.companySettings.nutrientdecimal;
      user.ingredientdecimal = this.companySettings.ingredientdecimal;
      user.forceproduct = this.companySettings.forceproduct;
      user.symbolinfront = this.companySettings.symbolinfront;
      user.boarding = true;
      localStorage.setItem("userData", JSON.stringify(user));
      this.$router.push({ name: "dashboard" });
    },
    validationForm() {
      if (this.plantType != 0) return true;
      return false;
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },

    validationFormSocial() {
      if (
        this.companySettings.symbol == null ||
        this.companySettings.symbol == ""
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please enter symbol",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
